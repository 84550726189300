<template>
	<Transition name="slide-fade" appear>
        <div class="xl:w-4/6 2xl:w-2/6 m-auto shadow-t3 rounded flex justify-center bg-white  container"> 
            <div class="p-12 m-auto rounded items-center relative md:mt-0">
                <router-link :to="{name:'Home_Customer'}" class=" text-md py-2  font-normal block w-full whitespace-nowrap bg-transparent text-blue-400">Back to home</router-link>

                <div class="">
                            <h1 class="text-xl font-bold text-t3 sm:text-2xl dark:text-white" >Raise Disputes</h1>
                            <h2 class="text-sm mt-8 font-medium text-gray-400 sm:text-lg dark:text-white" >Your dispute will be raised to our team, we will keep you updated about your dispute's status.</h2>
                
                </div>

                <div class="w-5/6 m-auto mt-12 text-center">
                                    <div class=" relative">
                                        <select  v-model="assessmentSend" class="h-12  form-control block w-full p-3 text-xl font-normal text-gray-400 bg-white focus:text-t3 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none">
                                            <option disabled :value="null">Select Assessment</option>
                                            <option v-for="assessment in freeAssess"  :value="assessment.id" :key="assessment.id">Date:{{new Date(assessment.date).toLocaleDateString('en-GB',{year: 'numeric', month: 'short', day: 'numeric'})}} Time:{{assessment.start_time}}</option> 
                                        </select>
                                        <textarea v-model="reason" placeholder="Justify the reason for dispute..." name="" id="" cols="30" rows="8" class="mt-12 form-control block w-full p-3 text-xl font-normal text-gray-400 bg-white focus:text-t3 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none">

                                        </textarea>

                                    </div>
                                    <div class=" flex flex-shrink-0 flex-wrap items-center justify-center p-4 rounded-b-md">
                                        <button type="button"
                                        @click="ReqSend" class=" w-48 m-auto  font-medium bg-t3 p-3 rounded mt-14 text-white hover:text-white hover:bg-t3-secondary block">
                                        Raise Dispute
                                        </button>
                                    </div>
                </div>



            </div>
        </div>
    </Transition>
</template>

<script>
      import {
mapActions, mapState
} from 'vuex';
import AssessmentsServices from "../services/AssessmentsServices";

    import ReportServices from '@/services/ReportServices';
import Swal from 'sweetalert2';

    export default {

        data() {
            return {
                assessmentSend:null,
                reason:null,
            }
        },
        
        methods: {
            ...mapActions({
                getassesssments:'Assessments/getAssessmentCustomer',
                getsettings:'Settings/getAllSettings',
            }),
            ReqSend(){
                if(this.assessmentSend && this.reason)
                {
                    let sendingObj = {
                        reason_of_dispute:this.reason,
                        type:"external",
                    }
                    const getFormData = object => Object.entries(object).reduce((fd, [ key, val ]) => {
                    if (Array.isArray(val)) {
                    val.forEach(v => fd.append(key, v))
                    } else {
                    fd.append(key, val)
                    }
                    return fd
                    }, new FormData())
                    AssessmentsServices.raiseDispute(this.assessmentSend,getFormData(sendingObj)).then(()=>{
                                Swal.fire({
                                icon:"success",
                                title:"Success!",
                                text:"Dispute raised!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: 3000,})
                                this.assessmentSend = null
                                this.reason=null
                    }).catch((error)=>{
                        let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {
  
          let user = JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
				}
			})
                    })
                    
                }
                else{
                    Swal.fire({
                                icon:"warning",
                                title:"Hold",
                                text:"Fill in all the required fields first",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: 3000,})
                }
            },
			scopeCheck(scope){
				let user= JSON.parse(localStorage.getItem('userdetail'))
				

				if(user.is_company){
                    
				return true;
				}else{
				return localStorage.getItem('scopes').includes(scope)}
			}		,
            
        },
        computed: {
            freeAssess(){
                return this.assessments.filter(assess => {
                    let todays = new Date().getTime()
                    let checkd = new Date(assess.completion_date)
                    checkd.setDate(checkd.getDate() + this.settings.allow_customer_to_raise_dispute_within)
                    if(assess.status === 'completed' && todays <= checkd.getTime()){
                        return true
                    }
                    else{
                        return false
                    }
                } )
           
            },
            ...mapState({
                slots: state => state.Slots.slots,
                booked: state => state.Requests.bookings,
                assessments: state => state.Assessments.assessmentCustomer,
                settings: state => state.Settings.settings
            }),
        },

        created() {
            this.getsettings().then(()=>{
                
            // this.getassesssments(user.id).then(()=>{
            //     this.freeAssess = this.assessments.filter(assess => {
            //         let todays = new Date().getTime()
            //         let checkd = new Date(assess.completion_time)
            //         checkd.setDate(checkd.getDate() + this.settings.allow_customer_to_raise_dispute_within)
            //         if(assess.status === 'completed' && todays <= checkd.getTime()){
            //             return true
            //         }
            //         else{
            //             return false
            //         }
            //     } )
            // })
            
          
            // })
            let user = JSON.parse(localStorage.getItem('userdetail'))
            this.getassesssments(user.id)})
          
             

}
    }
</script>

<style>

</style>
